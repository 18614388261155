import ResultItem from "./ResultItem";
import classes from "./ResultsList.module.css";

const ResultsList = (props) => {
  console.log("Evaluate ResultsList");

  const { items, onItemClick } = props;

  return (
    <ul className={classes["results-list"]}>
      {items.map((item) => (
        <ResultItem
          key={item.code}
          code={item.code}
          name={item.name}
          country={item.country}
          onItemClick={() => onItemClick(item.code)}
        />
      ))}
    </ul>
  );
};

export default ResultsList;

import React, { useContext, useState } from "react";

import QuoteContext from "../../../store/quote-context";
import classes from "./FilterSelection.module.css";
import closebutton from "../../../assets/closebutton.jpg";

const FilterSelection = (props) => {
  console.log("Evaluate FilterSelection");

  const ctx = useContext(QuoteContext);
  const [checkboxStates, setCheckboxStates] = useState({
    ys: false,
    xby: false,
    yydl: false,
    adcc: false,
    dfx: false,
  });

  const checkedChangeHandler = (name) => {
    setCheckboxStates({ ...checkboxStates, [name]: !checkboxStates[name] });
  };

  const searchClickHandler = () => {
    let filters = [];
    for (const key in checkboxStates) {
      if (checkboxStates[key]) {
        filters = filters.concat(key);
      }
    }
    ctx.onUpdateFilterResults("myx", "1d", filters);
  };

  const clearClickHandler = () => {
    ctx.onUpdateFilterResults("myx", "1d", []);
  };

  return (
    <div>
      <div className={classes["top-container"]}>
        <label>Screening</label>
        <button
          className={classes["top-container__close-button"]}
          onClick={props.onClose}
        >
          <img src={closebutton} alt="close screening panel" title="Close" />
        </button>
      </div>
      <div className={classes["checkbox-container"]}>
        <div className={classes["checkbox-container__column"]}>
          <label>
            <input
              type="checkbox"
              name="ys"
              checked={checkboxStates.ys}
              onChange={() => checkedChangeHandler("ys")}
            />
            <span className={classes["checkbox-label"]}>阳胜</span>
          </label>
          <label>
            <input
              type="checkbox"
              name="yydl"
              checked={checkboxStates.yydl}
              onChange={() => checkedChangeHandler("yydl")}
            />
            <span className={classes["checkbox-label"]}>以逸待劳</span>
          </label>
          <label>
            <input
              type="checkbox"
              name="adcc"
              checked={checkboxStates.adcc}
              onChange={() => checkedChangeHandler("adcc")}
            />
            <span className={classes["checkbox-label"]}>暗度成仓</span>
          </label>
          <label>
            <input
              type="checkbox"
              name="dfx"
              checked={checkboxStates.dfx}
              onChange={() => checkedChangeHandler("dfx")}
            />
            <span className={classes["checkbox-label"]}>低分型</span>
          </label>
        </div>
        <div className={classes["checkbox-container__column"]}>
          <label>
            <input
              type="checkbox"
              name="xby"
              checked={checkboxStates.xby}
              onChange={() => checkedChangeHandler("xby")}
            />
            <span className={classes["checkbox-label"]}>小倍阳</span>
          </label>
        </div>
      </div>
      <button
        className={classes["filter-selection-button"]}
        onClick={searchClickHandler}
      >
        Search
      </button>
      <button
        className={classes["filter-selection-button"]}
        onClick={clearClickHandler}
      >
        Clear
      </button>
    </div>
  );
};

export default FilterSelection;

import myxicon from "../../../assets/myxicon.jpg";
import usaicon from "../../../assets/usaicon.jpg";
import questionmarkicon from "../../../assets/questionmarkicon.jpg";

import classes from "./ResultItem.module.css";

const ResultItem = (props) => {
  const { code, name, country, onItemClick } = props;

  return (
    <li className={classes["result-item"]} onMouseDown={onItemClick}>
      <div className={classes["result-item__code"]}>{code}</div>
      <div className={classes["result-item__name"]}>{name}</div>
      <div className={classes["result-item__image"]}>
        <img
          src={
            country === "Malaysia"
              ? myxicon
              : country === "USA"
              ? usaicon
              : questionmarkicon
          }
          alt="Country"
        ></img>
      </div>
    </li>
  );
};

export default ResultItem;

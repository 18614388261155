import StockInfo from "./StockInfo";
import StockOHLC from "./StockOHLC";

import classes from "./StockInfoBar.module.css";

const StockInfoBar = (props) => {
  console.log("Evaluate StockInfoBar");

  const { symbol, interval, market, ohlc } = props;

  return (
    <div className={classes["stock-info-bar"]}>
      <StockInfo symbol={symbol} interval={interval} market={market} />
      <StockOHLC ohlc={ohlc} />
    </div>
  );
};

export default StockInfoBar;

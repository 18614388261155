import React, { useState, useContext } from "react";

import QuoteContext from "../../store/quote-context";
import StockInfoBar from "./StockInfoBar/StockInfoBar";
import StockChart from "./StockChart";
import classes from "./MainPanel.module.css";

const MainPanel = (props) => {
  console.log("Evaluate MainPanel");

  const { disableInteraction } = props;

  const [dataOHLC, setDataOHLC] = useState({});

  const ctx = useContext(QuoteContext);
  const symbolName = ctx.symbolName;
  const interval = ctx.interval;
  const market = ctx.market;
  const klinedata = ctx.klinedata;

  const changeOHLCHandler = (ohlc) => {
    setDataOHLC(ohlc);
  };

  return (
    <div className={classes["main-panel"]}>
      {klinedata !== undefined && (
        <StockInfoBar
          symbol={symbolName}
          interval={interval === "1wk" ? "1W" : interval.toUpperCase()}
          market={market.toUpperCase()}
          ohlc={dataOHLC}
        />
      )}
      <div
        className={disableInteraction ? classes["main-panel__disabled"] : ""}
      >
        {klinedata !== undefined && (
          <StockChart
            {...props}
            data={klinedata}
            onChangeOHLC={changeOHLCHandler}
          ></StockChart>
        )}
      </div>
    </div>
  );
};

export default MainPanel;

import React from "react";

import IndicatorPanel from "./IndicatorPanel/IndicatorPanel";
import ScreeningPanel from "./ScreeningPanel/ScreeningPanel";
import classes from "./RightPanel.module.css";

const RightPanel = (props) => {
  console.log("Evaluate RightPanel");

  return (
    <div className={classes["right-panel"]}>
      {props.page === "indicator" && <IndicatorPanel onClose={props.onClose} />}
      {props.page === "screening" && <ScreeningPanel onClose={props.onClose} />}
    </div>
  );
};

export default RightPanel;

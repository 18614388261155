import { Fragment, useState, useContext } from "react";
import { Auth } from "aws-amplify";

import QuoteContext from "../../store/quote-context";
import Button from "../UI/Button/Button";
import SearchBar from "./SearchBar/SearchBar";
import IntervalsList from "./IntervalsList/IntervalsList";
import ResultsList from "./ResultsList/ResultsList";
import stockindicators from "../../assets/stock-indicators.jpg";
import stockscreen from "../../assets/stock-screen.jpg";
import classes from "./TopPanel.module.css";

const TopPanel = (props) => {
  console.log("Evaluate TopPanel");

  const { onResultsListVisibleChanged, onRightPanelPageChanged } = props;

  const ctx = useContext(QuoteContext);

  const [searchValue, setSearchValue] = useState("");
  const [showResultsList, setShowResultsList] = useState();

  const filteredData = ctx.symbolsList.filter((symbol) => {
    const searchTerm = searchValue.toUpperCase();
    if (symbol.code !== undefined) {
      const code = symbol.code.toUpperCase();
      const match = searchTerm && code.includes(searchTerm);
      if (match) {
        return true;
      }
    }

    if (symbol.name !== undefined) {
      const name = symbol.name.toUpperCase();
      const match = searchTerm && name.includes(searchTerm);
      if (match) {
        return true;
      }
    }

    return false;
  });

  const searchValueChangeHandler = (event) => {
    setSearchValue(event.target.value.toUpperCase());
  };

  const showResultsListHandler = () => {
    setShowResultsList(true);
    onResultsListVisibleChanged(true);
  };

  const hideResultsListHandler = () => {
    setShowResultsList(false);
    onResultsListVisibleChanged(false);
  };

  const itemClickHandler = (itemCode) => {
    setSearchValue(itemCode.toUpperCase());
    ctx.onUpdateSymbol(itemCode);
  };

  const showRightPanelClickHandler = (page) => {
    onRightPanelPageChanged(page);
  };

  const signOutHandler = (e) => {
    try {
      Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
      window.alert("error signing out: ", error);
    }
  };

  return (
    <Fragment>
      <div className={classes["top-panel-container"]}>
        <SearchBar
          value={searchValue}
          placeholder="Search Symbol"
          onChange={searchValueChangeHandler}
          onFocus={showResultsListHandler}
          onBlur={hideResultsListHandler}
        ></SearchBar>
        <div className={classes["top-panel-container__separator"]}></div>
        <IntervalsList />
        <button
          className={classes["top-panel-container__button"]}
          onClick={() => showRightPanelClickHandler("indicator")}
        >
          <img
            src={stockindicators}
            alt="stock indicators"
            title="Indicators"
          />
        </button>
        <button
          className={classes["top-panel-container__button"]}
          onClick={() => showRightPanelClickHandler("screening")}
        >
          <img src={stockscreen} alt="stock screen" title="Screening" />
        </button>
        <Button onClick={signOutHandler}>Logout</Button>
      </div>
      {showResultsList && filteredData.length !== 0 && (
        <ResultsList items={filteredData} onItemClick={itemClickHandler} />
      )}
    </Fragment>
  );
};

export default TopPanel;

import React, { useContext } from "react";

import QuoteContext from "../../../store/quote-context";
import classes from "./IndicatorSelection.module.css";
import closebutton from "../../../assets/closebutton.jpg";

const IndicatorSelection = (props) => {
  console.log("Evaluate IndicatorSelection");

  const ctx = useContext(QuoteContext);

  const checkedChangeHandler = (indicator) => {
    ctx.onUpdateIndicators({
      ...ctx.indicators,
      [indicator]: !ctx.indicators[indicator],
    });
  };

  return (
    <div>
      <div className={classes["top-container"]}>
        <label>Indicators</label>
        <button
          className={classes["top-container__close-button"]}
          onClick={props.onClose}
        >
          <img src={closebutton} alt="close indicator panel" title="Close" />
        </button>
      </div>
      <div className={classes["checkbox-container"]}>
        <div className={classes["checkbox-container__column"]}>
          <label>
            <input
              type="checkbox"
              name="ema10"
              checked={ctx.indicators.ema10}
              onChange={() => checkedChangeHandler("ema10")}
            />
            <span className={classes["checkbox-label"]}>Ema10</span>
          </label>
          <label>
            <input
              type="checkbox"
              name="ema25"
              checked={ctx.indicators.ema25}
              onChange={() => checkedChangeHandler("ema25")}
            />
            <span className={classes["checkbox-label"]}>Ema25</span>
          </label>
          <label>
            <input
              type="checkbox"
              name="ema50"
              checked={ctx.indicators.ema50}
              onChange={() => checkedChangeHandler("ema50")}
            />
            <span className={classes["checkbox-label"]}>Ema50</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSelection;

import { useContext } from "react";

import QuoteContext from "../../../store/quote-context";
import classes from "./IntervalsList.module.css";

const IntervalsList = () => {
  console.log("Evaluate IntervalsList");

  const ctx = useContext(QuoteContext);

  const handleIntervalClick = (interval) => {
    ctx.onUpdateInterval(interval);
  };

  return (
    <ul className={classes["intervals-list"]}>
      <li
        onClick={() => handleIntervalClick("1d")}
        className={ctx.interval === "1d" ? classes["active"] : ""}
        title="1 day"
      >
        D
      </li>
      <li
        onClick={() => handleIntervalClick("1wk")}
        className={ctx.interval === "1wk" ? classes["active"] : ""}
        title="1 week"
      >
        W
      </li>
    </ul>
  );
};

export default IntervalsList;

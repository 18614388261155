import { useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";

import "./App.css";
import TopPanel from "./components/TopPanel/TopPanel";
import MainPanel from "./components/MainPanel/MainPanel";
import RightPanel from "./components/RightPanel/RightPanel";

function App() {
  console.log("Evaluate App");

  const [mainPanelDisableInteraction, setMainPanelDisableInteraction] =
    useState();

  const [rightPanelPage, setRightPanelPage] = useState();

  const resultsListVisibleChangedHandler = (visible) => {
    setMainPanelDisableInteraction(visible);
  };

  const rightPanelPageChangedHandler = (page) => {
    setRightPanelPage(page);
  };

  const rightPanelCloseHandler = () => {
    setRightPanelPage("");
  };

  return (
    <div className="App">
      <TopPanel
        onResultsListVisibleChanged={resultsListVisibleChangedHandler}
        onRightPanelPageChanged={rightPanelPageChangedHandler}
      />
      <div className="App-Mid-Panel">
        <div className="App-Main-Panel">
          <MainPanel disableInteraction={mainPanelDisableInteraction} />
        </div>
        {rightPanelPage !== "" && (
          <div className="App-Right-Panel">
            <RightPanel
              page={rightPanelPage}
              onClose={rightPanelCloseHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(App);

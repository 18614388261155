import React from "react";

const QuoteContext = React.createContext({
  symbolsList: [],
  symbol: "",
  symbolName: "",
  market: "",
  interval: "",
  klinedata: {},
  indicators: {},
  pattern: {},
  filterResults: [],
  onUpdateSymbol: (newSymbol) => {},
  onUpdateInterval: (newInterval) => {},
  onUpdateIndicators: (indicators) => {},
  onUpdatePattern: (pattern) => {},
  onUpdateFilterResults: (market, interval, newFilters) => {},
});

export default QuoteContext;

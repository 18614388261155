import React from "react";

import IndicatorSelection from "./IndicatorSelection";
import PatternSelection from "./PatternSelection";
import classes from "./IndicatorPanel.module.css";

const IndicatorPanel = (props) => {
  console.log("Evaluate IndicatorPanel");

  return (
    <div>
      <IndicatorSelection onClose={props.onClose} />
      <div className={classes["indicator-panel__separator"]}></div>
      <PatternSelection />
    </div>
  );
};

export default IndicatorPanel;

import React, { useEffect, useRef, useContext } from "react";
import { createChart, ColorType } from "lightweight-charts";

import QuoteContext from "../../store/quote-context";

const StockChart = (props) => {
  console.log("Evaluate StockChart");

  const ctx = useContext(QuoteContext);

  const {
    data: klinedata,
    colors: {
      backgroundColor = "white",
      lineColor = "#2962FF",
      textColor = "black",
      areaTopColor = "#2962FF",
      areaBottomColor = "rgba(41, 98, 255, 0.28)",
    } = {},
    onChangeOHLC,
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    console.log("StockChart useEffect - createChart");

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 800,
    });
    //chart.timeScale().fitContent();

    // Calculate the timestamp for 6 months ago
    var sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // Create a range to set the visible time range
    var range = {
      from: sixMonthsAgo.getTime() / 1000, // Divide by 1000 to convert to seconds
      to: Date.now() / 1000, // Divide by 1000 to convert to seconds
    };

    // Set the initial timescale to 6 months
    chart.applyOptions({
      timeScale: {
        visibleRange: range,
      },
    });

    //Candle
    const candleseries = chart.addCandlestickSeries();
    candleseries.setData(klinedata);

    if (ctx.indicators.ema10) {
      //EMA10
      const ema10_series = chart.addLineSeries({
        color: "gray",
        lineWidth: 1,
      });
      const ema10_data = klinedata
        .filter((d) => d.ema10)
        .map((d) => ({ time: d.time, value: d.ema10 }));
      ema10_series.setData(ema10_data);
    }

    if (ctx.indicators.ema25) {
      //EMA25
      const ema25_series = chart.addLineSeries({
        color: "red",
        lineWidth: 1,
      });
      const ema25_data = klinedata
        .filter((d) => d.ema25)
        .map((d) => ({ time: d.time, value: d.ema25 }));
      ema25_series.setData(ema25_data);
    }

    if (ctx.indicators.ema50) {
      //EMA50
      const ema50_series = chart.addLineSeries({
        color: "blue",
        lineWidth: 1,
      });
      const ema50_data = klinedata
        .filter((d) => d.ema50)
        .map((d) => ({ time: d.time, value: d.ema50 }));
      ema50_series.setData(ema50_data);
    }

    //MARKERS
    if (ctx.pattern === "ys") {
      const marker = klinedata
        .filter((d) => d.ys)
        .map((d) =>
          d.ys
            ? {
                time: d.time,
                position: "belowBar",
                color: "#FFD700",
                shape: "arrowUp",
                text: "阳胜",
              }
            : {}
        );
      candleseries.setMarkers(marker);
    }

    if (ctx.pattern === "xby") {
      const marker = klinedata
        .filter((d) => d.xby)
        .map((d) =>
          d.xby
            ? {
                time: d.time,
                position: "belowBar",
                color: "orange",
                shape: "arrowUp",
                text: "小倍阳",
              }
            : {}
        );
      candleseries.setMarkers(marker);
    }

    if (ctx.pattern === "yydl") {
      const marker = klinedata
        .filter((d) => d.yydl)
        .map((d) =>
          d.yydl
            ? {
                time: d.time,
                position: "belowBar",
                color: "purple",
                shape: "arrowUp",
                text: "以逸待劳",
              }
            : {}
        );
      candleseries.setMarkers(marker);
    }

    if (ctx.pattern === "adcc") {
      const marker = klinedata
        .filter((d) => d.adcc)
        .map((d) =>
          d.adcc
            ? {
                time: d.time,
                position: "belowBar",
                color: "purple",
                shape: "arrowUp",
                text: "暗度成仓",
              }
            : {}
        );
      candleseries.setMarkers(marker);
    }

    if (ctx.pattern === "dfx") {
      const marker = klinedata
        .filter((d) => d.dfx)
        .map((d) =>
          d.dfx
            ? {
                time: d.time,
                position: "belowBar",
                color: "blue",
                shape: "arrowUp",
                text: "低分型",
              }
            : {}
        );
      candleseries.setMarkers(marker);
    }

    //OHLC VALUES
    chart.subscribeCrosshairMove((param) => {
      const ohlc = param.seriesData.get(candleseries);
      if (ohlc !== undefined) {
        onChangeOHLC(ohlc);
      }
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    klinedata,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    ctx.indicators,
    ctx.pattern,
  ]);

  return <div ref={chartContainerRef} />;
};

export default StockChart;

import MagnifyingGlassIcon from "./MagnifyingGlassIcon";
import classes from "./SearchBar.module.css";

const SearchBar = (props) => {
  console.log("Evaluate SearchBar");

  const { value, placeholder, onChange, onFocus, onBlur } = props;

  return (
    <div className={classes["search-bar"]}>
      <div className={classes["search-bar__icon"]}>
        <MagnifyingGlassIcon />
      </div>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default SearchBar;

import classes from "./StockInfo.module.css";

const StockInfo = (props) => {
  const { symbol, interval, market } = props;

  return (
    <div className={classes["stock-info"]}>
      <label>{symbol}</label>
      <label>&middot;</label>
      <label>{interval}</label>
      <label>&middot;</label>
      <label>{market}</label>
    </div>
  );
};

export default StockInfo;

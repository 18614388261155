import React, { useContext } from "react";

import QuoteContext from "../../../store/quote-context";
import classes from "./ScreenResult.module.css";

const ScreenResult = (props) => {
  console.log("Evaluate ScreenResult");

  const ctx = useContext(QuoteContext);

  const getNameByCode = (code) => {
    const symbol = ctx.symbolsList.find((symbol) => symbol.code === code);
    if (symbol) {
      return symbol.name;
    }
    return code;
  };

  const resultClickHandler = (result) => {
    ctx.onUpdateSymbol(result);
  };

  return (
    <div>
      <label>
        <span className={classes["result-label"]}>
          Result ({ctx.filterResults.length}):
        </span>
      </label>
      <div className={classes["result-list-container"]}>
        <ul className={classes["result-list"]}>
          {ctx.filterResults.map((result, index) => (
            <li
              key={index}
              onClick={() => resultClickHandler(result)}
              className={classes["result-list-item"]}
            >
              {getNameByCode(result)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ScreenResult;

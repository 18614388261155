import React from "react";

import FilterSelection from "./FilterSelection";
import ScreenResult from "./ScreenResult";
import classes from "./ScreeningPanel.module.css";

const ScreeningPanel = (props) => {
  console.log("Evaluate ScreeningPanel");

  return (
    <div>
      <FilterSelection onClose={props.onClose} />
      <div className={classes["screening-panel__separator"]}></div>
      <ScreenResult />
    </div>
  );
};

export default ScreeningPanel;

import classes from "./StockOHLC.module.css";

const StockOHLC = (props) => {
  const { ohlc: { open, high, low, close } = {} } = props;

  return (
    <div className={classes["stock-ohlc"]}>
      <strong>O</strong>
      <span className={classes[open > close ? "red" : "green"]}>
        {open}
      </span>{" "}
      <strong>H</strong>
      <span className={classes[open > close ? "red" : "green"]}>
        {high}
      </span>{" "}
      <strong>L</strong>
      <span className={classes[open > close ? "red" : "green"]}>
        {low}
      </span>{" "}
      <strong>C</strong>
      <span className={classes[open > close ? "red" : "green"]}>{close}</span>
    </div>
  );
};

export default StockOHLC;

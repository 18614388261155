import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Storage } from "aws-amplify";

import QuoteContext from "./quote-context";

// use express server (http request)
// const getData = async (market, symbol, interval) => {
//   if (market === "myx") {
//     if (!symbol.endsWith(".KL")) {
//       symbol = symbol + ".KL";
//     }
//   } else if (market === "crypto") {
//     if (!symbol.endsWith("-USD")) {
//       symbol = symbol + "-USD";
//     }
//   }
//   let data_url = "";
//   if (this.use_test_server) {
//     data_url = `http://127.0.0.1:3001/${market}/${symbol}/${interval}`;
//   } else {
//     data_url = `http://127.0.0.1:3000/${market}/${symbol}/${interval}`;
//   }
//   const resp = await fetch(data_url);
//   const data = await resp.json();
//   return data;
// };

// const getSymbolName = async (market, symbol) => {
//   let data_url = "";
//   if (this.use_test_server) {
//     data_url = `http://127.0.0.1:3001/getname?m=${market}&&s=${symbol}`;
//   } else {
//     data_url = `http://127.0.0.1:3000/getname?m=${market}&&s=${symbol}`;
//   }
//   const resp = await fetch(data_url);
//   const data = await resp.json();
//   return data;
// };

// const getFilter = async (market, interval, indicator) => {
//   let data_url = "";
//   if (this.use_test_server) {
//     data_url = `http://127.0.0.1:3001/filter?m=${market}&&i=${interval}&&f=${indicator}`;
//   } else {
//     data_url = `http://127.0.0.1:3000/filter?m=${market}&&i=${interval}&&f=${indicator}`;
//   }
//   const resp = await fetch(data_url);
//   const data = await resp.json();
//   return data;
// };

// use AWS S3 storage
const getData = async (market, symbol, interval) => {
  try {
    let filePath;
    if (market === "myx") {
      if (!symbol.endsWith(".KL")) {
        symbol = symbol + ".KL";
      }
      filePath = `data/${interval}/stock/Malaysia/${symbol}.json`;
    } else if (market === "usa") {
      filePath = `data/${interval}/stock/USA/${symbol}.json`;
    } else if (market === "crypto") {
      if (!symbol.endsWith("-USD")) {
        symbol = symbol + "-USD";
      }
      filePath = `data/${interval}/crypto/${symbol}.json`;
    }
    const result = await Storage.get(filePath, { download: true });
    const resultString = await result.Body.text();
    const data = JSON.parse(resultString);
    return data;
  } catch (error) {
    console.log(`getData error: ${error}`);
    // window.alert(`getData error: ${error}`);
    // return a default value or throw an error here if needed
  }
};

const getSymbolName = async (market, symbol) => {
  try {
    symbol = symbol.toUpperCase();
    let filePath = "";
    if (market === "myx") {
      symbol = symbol.replace(".KL", "");
      filePath = `list/KLSE_LIST_OF_SYMBOLS.csv`;
    } else if (market === "usa") {
      filePath = `list/US_LIST_OF_SYMBOLS.csv`;
    } else if (market === "crypto") {
      symbol = symbol.replace("-USD", "");
      filePath = `list/cryptocurrencies.json`;
    }
    let name = "";
    if (market === "myx" || market === "usa") {
      const result = await Storage.get(filePath, { download: true });
      const listOfSymbols = await result.Body.text();
      const symbols = listOfSymbols
        .split("\n")
        .slice(1) // slice header
        .map((row) => {
          const [code, name] = row.split(",");
          return {
            code: code,
            name: name,
          };
        });
      name = symbols.find((s) => s.code === symbol)?.name ?? "";
    } else if (market === "crypto") {
      const result = await Storage.get(filePath, { download: true });
      const listOfCryptocurrencies = await result.Body.text();
      const cryptocurrencies = JSON.parse(listOfCryptocurrencies);
      name = JSON.stringify(cryptocurrencies[symbol]);
    }
    return JSON.parse(name);
  } catch (error) {
    console.log(`getSymbolName error: ${error}`);
    // window.alert(`getSymbolName error: ${error}`);
  }
};

const getFilter = async (market, interval, indicator) => {
  try {
    let filePath;
    if (market === "myx") {
      filePath = `filter/${interval}/stock/Malaysia/${indicator}.json`;
    } else if (market === "usa") {
      filePath = `filter/${interval}/stock/USA/${indicator}.json`;
    } else if (market === "crypto") {
      filePath = `filter/${interval}/crypto/${indicator}.json`;
    }
    const result = await Storage.get(filePath, { download: true });
    const resultString = await result.Body.text();
    const data = JSON.parse(resultString);
    return data;
  } catch (error) {
    console.log(`getFilter error: ${error}`);
    return [];
  }
};

const getSymbolsList = async (filePath) => {
  try {
    // Retrieve the CSV file from S3 using Storage.get
    const response = await Storage.get(filePath, { download: true });
    const csvContent = await response.Body.text();

    // Parse the CSV content using Papa.parse
    const { data: parsedData } = Papa.parse(csvContent, { header: true });

    // Filter and map the required fields
    let objects = parsedData.map(({ Code, Name, Country }) => ({
      code: Code,
      name: Name,
      country: Country,
    }));

    return objects;
  } catch (error) {
    console.error("Error retrieving or processing the CSV file:", error);
  }
};

const QuoteContextProvider = (props) => {
  console.log("Evaluate QuoteContextProvider");

  const [symbolsList, setSymbolsList] = useState([]);
  const [symbol, setSymbol] = useState("1295");
  const [symbolName, setSymbolName] = useState("Public Bank Bhd");
  const [market, setMarket] = useState("myx");
  const [interval, setInterval] = useState("1d");
  const [klinedata, setKlinedata] = useState(undefined);
  const [indicators, setIndicators] = useState({
    ema10: true,
    ema25: true,
    ema50: true,
    macd: false,
  });
  const [pattern, setPattern] = useState("None");
  const [filterResults, setFilterResults] = useState([]);

  useEffect(() => {
    console.log("QuoteContextProvider useEffect - fetchSymbols");

    const fetchSymbols = async () => {
      const myxSymbols = await getSymbolsList("list/KLSE_LIST_OF_SYMBOLS.csv");
      // eslint-disable-next-line
      const usSymbols = await getSymbolsList(`list/US_LIST_OF_SYMBOLS.csv`);
      const mockUsSymbols = [
        { code: "AAPL", name: "Apple Inc", country: "USA" },
        { code: "TSLA", name: "Tesla Inc", country: "USA" },
        { code: "GOOG", name: "Alphabet Inc Class C", country: "USA" },
        { code: "MSFT", name: "Microsoft Corporation", country: "USA" },
        { code: "INTC", name: "Intel Corporation", country: "USA" },
      ];

      console.log("QuoteContextProvider setSymbolsList");
      setSymbolsList(myxSymbols.concat(mockUsSymbols));
    };

    fetchSymbols();
  }, []);

  useEffect(() => {
    console.log("QuoteContextProvider useEffect - fetchData");

    const fetchData = async () => {
      const data = await getData(market, symbol, interval);
      let name = await getSymbolName(market, symbol);
      if (name === undefined) {
        name = symbol;
      }

      console.log("QuoteContextProvider setKlinedata");
      setKlinedata(data);
      console.log("QuoteContextProvider setSymbolName");
      setSymbolName(name);
    };

    fetchData();
  }, [market, symbol, interval]);

  const getMarketByCode = (code) => {
    const symbol = symbolsList.find((symbol) => symbol.code === code);
    if (symbol) {
      if (symbol.country === "Malaysia") {
        return "myx";
      }
      return symbol.country.toLowerCase();
    }
    return null;
  };

  const updateSymbolHandler = (newSymbol) => {
    console.log("QuoteContextProvider setSymbol");
    setSymbol(newSymbol);
    console.log("QuoteContextProvider setMarket");
    const market = getMarketByCode(newSymbol);
    if (null !== market) {
      setMarket(market);
    } else {
      setMarket("");
    }
  };

  const updateIntervalHandler = (newInterval) => {
    console.log("QuoteContextProvider setInterval");
    setInterval(newInterval);
  };

  const updateIndicatorsHandler = (indicators) => {
    console.log("QuoteContextProvider setIndicators");
    setIndicators(indicators);
  };

  const updatePatternHandler = (pattern) => {
    console.log("QuoteContextProvider setPattern");
    setPattern(pattern);
  };

  const updateFilterResultsHandler = (market, interval, newFilters) => {
    console.log("QuoteContextProvider setFilterResults");
    const fetchFilter = async (filters) => {
      let results = [];
      for (const filter of filters) {
        const data = await getFilter(market, interval, filter);
        results =
          results.length === 0
            ? data
            : results.filter((result) => data.includes(result));
      }
      setFilterResults(results);
    };

    fetchFilter(newFilters);
  };

  return (
    <QuoteContext.Provider
      value={{
        symbolsList: symbolsList,
        symbol: symbol,
        symbolName: symbolName,
        market: market,
        interval: interval,
        klinedata: klinedata,
        indicators: indicators,
        pattern: pattern,
        filterResults: filterResults,
        onUpdateSymbol: updateSymbolHandler,
        onUpdateInterval: updateIntervalHandler,
        onUpdateIndicators: updateIndicatorsHandler,
        onUpdatePattern: updatePatternHandler,
        onUpdateFilterResults: updateFilterResultsHandler,
      }}
    >
      {props.children}
    </QuoteContext.Provider>
  );
};

export default QuoteContextProvider;

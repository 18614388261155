import React, { useContext } from "react";

import QuoteContext from "../../../store/quote-context";
import classes from "./PatternSelection.module.css";

const PatternSelection = (props) => {
  console.log("Evaluate PatternSelection");

  const ctx = useContext(QuoteContext);

  const checkedChangeHandler = (pattern) => {
    ctx.onUpdatePattern(pattern);
  };

  return (
    <div>
      <div className={classes["top-container"]}>
        <label>Patterns</label>
      </div>
      <div className={classes["radio-container"]}>
        <div className={classes["radio-container__column"]}>
          <label>
            <input
              type="radio"
              name="None"
              checked={ctx.pattern === "None"}
              onChange={() => checkedChangeHandler("None")}
            />
            <span className={classes["radio-label"]}>None</span>
          </label>
          <label>
            <input
              type="radio"
              name="ys"
              checked={ctx.pattern === "ys"}
              onChange={() => checkedChangeHandler("ys")}
            />
            <span className={classes["radio-label"]}>阳胜</span>
          </label>
          <label>
            <input
              type="radio"
              name="xby"
              checked={ctx.pattern === "xby"}
              onChange={() => checkedChangeHandler("xby")}
            />
            <span className={classes["radio-label"]}>小倍阳</span>
          </label>
          <label>
            <input
              type="radio"
              name="yydl"
              checked={ctx.pattern === "yydl"}
              onChange={() => checkedChangeHandler("yydl")}
            />
            <span className={classes["radio-label"]}>以逸待劳</span>
          </label>
          <label>
            <input
              type="radio"
              name="adcc"
              checked={ctx.pattern === "adcc"}
              onChange={() => checkedChangeHandler("adcc")}
            />
            <span className={classes["radio-label"]}>暗度成仓</span>
          </label>
          <label>
            <input
              type="radio"
              name="dfx"
              checked={ctx.pattern === "dfx"}
              onChange={() => checkedChangeHandler("dfx")}
            />
            <span className={classes["radio-label"]}>低分型</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PatternSelection;
